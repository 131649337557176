import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

  return () => {
    ScrollTrigger.kill();
  };
}, [])

  return (
    <Section ref={ref} id="faq">
    <Title>Faq</Title>

    <Container>

<Box>
  <Accordion ScrollTrigger={ScrollTrigger} title="How do I contact 00RR0R dev team?" >
  Visit our Discord Server or message us on Twitter. 

  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="What is the cost?" >
  1 SOL and 1 00RR0R NFT per every 500 images in a collection.  An NFT collection can be any size between 100 - 10,000 NFTs
  On mint completion we charge 7.5% of mint proceeds, and 1% royalties from secondary sale via automatic smart contract.
  $30 USD (or SOL equilivant) if you need a custom domain and hosting setup. 


  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="Do I have to have to use your mint site/design?" >
  Not mandatory. It's there if you want it..</Accordion>

<Accordion ScrollTrigger={ScrollTrigger} title="Will these mints support whitelist?" >
Yes! Along with airdrop support.  Due to SOL network fees the cost will be 1 SOL for every 300 WL addresses.  This fee can be taken out from mint proceeds on mint close. 
</Accordion>
</Box>
<Box>
  
  <Accordion ScrollTrigger={ScrollTrigger} title="Will we be on Magic Eden, Have Rarity, and Sales Bots??" >
  00RR0R team will make sure you're listed on Secondary Markets hours (sometimes minutes) after mint closes.  Along with getting your project rarity setup.  Rarity Bot will require a small amount of technical knowledge from you or I can point you to services that can provide it as we work on an in-house solution. </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="Has anyone used 00RR0R Launchpad?" >
At the time of writing we've had 6 projects use our launchpad and have all sold out their mints.  Parlay, SolBouncers, Lemon Party NFT, Bowl Ramen, Grandpa's Story and Re-Devil's.
  </Accordion>

  <Accordion ScrollTrigger={ScrollTrigger} title="Will you provide marketing?" >
  No. Marketing and community growth is not something we will be able to assist you with.  We will announce new projects launching via our launchpad on our Discord/Twitter and are happy to give away possible WL to the community.  Beyond that, it's up to you to grow your community.</Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="My question wasn't answered!" >
Send us a message, we'll respond as soon as possible.
  </Accordion>
</Box>
    </Container>
    </Section>
  )
}

export default Faq